import { isPlatformServer } from '@angular/common';
import { inject, Injectable, PLATFORM_ID } from '@angular/core';
import {defer, from, Observable, of} from 'rxjs';
import {map} from "rxjs/operators";

@Injectable({
    providedIn: 'root',
})
export class StorageService {
    private platformId: Object = inject(PLATFORM_ID);

    public get$<T>(key: string, defaultValue?: T): Observable<T> {
        if (isPlatformServer(this.platformId)) {
            return of(defaultValue);
        }

        return defer(() =>
            from(of(localStorage.getItem(key))).pipe(
                map((value: any) => {
                    if (value === null) {
                        throw value;
                    }
                    return JSON.parse(value);
                })
            )
        );

    }

    public set(key: string, data: any): null {
        if (isPlatformServer(this.platformId)) {
            return null;
        }
        localStorage.setItem(key, JSON.stringify(data));
    }

    public remove(key: string): null {
        if (isPlatformServer(this.platformId)) {
            return null;
        }
        localStorage.removeItem(key);
    }

}
