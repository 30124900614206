import { DOCUMENT } from '@angular/common';
import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    Output,
    ViewChild,
    inject,
} from '@angular/core';

@Component({
    selector: 'wuc-modal',
    templateUrl: 'modal.component.html',
    styleUrls: ['modal.component.scss'],
})
export class WucModalComponent implements AfterViewInit {
    @ViewChild('modal') private modalRef!: ElementRef<HTMLElement>;

    private _isVisible: boolean = false;
    private document = inject(DOCUMENT);

    @Input()
    public set isVisible(isVisible: boolean) {
        this._isVisible = isVisible;
        this.updateVisibility();
    }

    @Input() public modalTitle?: string;
    @Input() public modalSubTitle?: string;
    @Input() public showClose: boolean = true;
    @Input() public closeButtonText?: string;
    @Input() public closeOnBackdropClick: boolean = true;
    @Input() public isFullScreen: boolean = false;

    @Output() public modalClose: EventEmitter<void> = new EventEmitter<void>();

    @HostListener('document:keydown.escape', ['$event'])
    handleEscape(event: KeyboardEvent): void {
        if (this.closeOnBackdropClick) {
            this.removeModal();
        }
    }

    public ngAfterViewInit(): void {
        this.updateVisibility();
    }

    public onCloseClick(): void {
        this.modalClose.emit();
    }

    public onOverlayClick(event: MouseEvent, overlay: HTMLDivElement): void {
        if (this.closeOnBackdropClick && event.target === overlay) {
            this.modalClose.emit();
        }
    }

    private insertModal(): void {
        if (this.modalRef && !this.document.body.querySelector('body > #wuc-modal-container')) {
            this.document.body.appendChild(this.modalRef.nativeElement);
            this.document.body.style.overflow = 'hidden';
        }
    }

    private removeModal(): void {
        const modalElement = this.document.body.querySelector('body > #wuc-modal-container');
        if (modalElement) {
            this.document.body.removeChild(modalElement);
        }
        this.document.body.style.overflow = null;
    }

    private updateVisibility(): void {
        this._isVisible ? this.insertModal() : this.removeModal();
    }
}
