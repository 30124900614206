import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NewModalModule } from 'outshared-lib';
import { ComplaintModal } from './complaint.modal';
import { ComplaintModalContainer } from './complaint.modal.container';
import { ModalComponentsModule } from '@app-de/components';
import { ListModule } from '@app-de/shared/lists/list.module';

@NgModule({
    imports: [
        CommonModule,
        NewModalModule,
        ListModule,
        ModalComponentsModule,
    ],
    exports: [
        ComplaintModal,
        ComplaintModalContainer,
    ],
    declarations: [
        ComplaintModal,
        ComplaintModalContainer,
    ],
})
export class ComplaintModalModule {}
