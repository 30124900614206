import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { REQUEST } from '../express.tokens';
import { AuthenticationInterceptor } from 'outshared-lib';
import { AppComponent } from './app.component';
import { AppModule } from './app.module';
import {
    CookieStorage,
    LocalStorage,
    LocalStorageService,
    SessionStorage,
    SessionStorageService,
    StorageService,
} from '@inshared/shared/util-storage';

// the Request object only lives on the server
export function getRequest(): any {
    return { headers: { cookie: document.cookie } };
}

@NgModule({
    bootstrap: [AppComponent],
    imports: [AppModule],
    providers: [
        {
            // The server provides these in main.server
            provide: REQUEST,
            useFactory: getRequest,
        },
        { provide: StorageService, useClass: CookieStorage },
        { provide: LocalStorageService, useClass: LocalStorage },
        { provide: SessionStorageService, useClass: SessionStorage },
        { provide: 'ORIGIN_URL', useValue: location.origin },
        { provide: HTTP_INTERCEPTORS, useClass: AuthenticationInterceptor, multi: true },
    ],
})
export class AppBrowserModule {}
