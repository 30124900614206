import { Inject, Injectable } from '@angular/core';
import {
    ChatbotElementPartInterface,
    WucChatbotAnswerInterface,
    WucChatbotDialogOptionInterface,
    WucChatbotEscalationFeedbackInterface,
    WucChatbotLinkInterface,
    WucChatbotVideoInterface,
} from '@inshared/website-ui-components';
import {
    OutChatbotService,
    ChatbotFaqList,
    ChatbotAnswerInterface,
    ChatbotElementInterface,
    AnalyticsActionService,
} from 'outshared-lib';
import { Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { LivechatService } from '@app-de/core/livechat/livechat.service';
import { LocalStorageService } from '@inshared/shared/util-storage';
@Injectable({
    providedIn: 'root',
})
export class InsChatbotService {
    private static LOCALSTORAGE_KEY: string = 'chatbot-session-id';

    private interactionId?: string;
    private regexMap: { [key: string]: RegExp } = {
        email: /(@|\b[\w\.-]+@[\w\.-]+\.\w{2,4}\b)/g,
        bankAccount:
            /\b(?:NL|BE)\d{2}\s*(?:[A-Z]{4}\s*)?(?:\d{10}|\d{4}\s*\d{4}\s*\d{4}|\d{2}\s*[A-Z]{4}\s*\d{4}\s*\d{2}|[A-Z]{4}\s*\d{1}\s*\d{4}\s*\d{5}|\d{2}\s*[A-Z]{4}\s*\d{4}\s*\d{4}\s*\d{2})\b|\b(?:NL|BE)\d{2}\s*[A-Z]{4}\s*\d{4}\s*\d{4}\s*\d{2}\b/gi,
        allNumbers: /\d+/g,
    };

    constructor(
        private readonly chatbotService: OutChatbotService,
        private liveChatService: LivechatService,
        @Inject(LocalStorageService) private localStorage: Storage,
        private analyticsActionService: AnalyticsActionService,
    ) {}

    public getSessionId(): string {
        return this.localStorage.getItem(InsChatbotService.LOCALSTORAGE_KEY);
    }

    public anonymizeText(text: string): string {
        return text
            .replace(this.regexMap.email, '*****@*****')
            .replace(this.regexMap.bankAccount, (match) => match.replace(/\S/g, '*'))
            .replace(this.regexMap.allNumbers, (match) => match.replace(/\S/g, '*'));
    }

    public askQuestion$(question: string, dialogPath: string): Observable<WucChatbotAnswerInterface> {
        return this.chatbotService.askQuestion$(question, this.getSessionId(), dialogPath).pipe(
            take(1),
            tap((answer: ChatbotAnswerInterface): void => {
                if (question) {
                    const dialogData = {
                        event: 'chatbot',
                        vraag: this.anonymizeText(question),
                        answer: this.mapToText(answer),
                    };
                    this.analyticsActionService.sendToDatalayer(dialogData);
                }
                this.saveIds(answer.sessionId, answer.interactionId);
                this.liveChatService.setChatbotHistory({
                    question: `${question} ${dialogPath ? '(dialog path: ' + dialogPath + ')' : ''}`,
                    answer: this.mapToText(answer),
                });
            }),
        );
    }

    public getFaqList$(classificationId: number, classificationName: string, step: number): Observable<ChatbotFaqList> {
        return this.chatbotService.getFaqList$(this.getSessionId(), classificationId, classificationName, step).pipe(
            take(1),
            tap((list: ChatbotFaqList): void => {
                this.saveIds(list.sessionId, list.interactionId);
            }),
        );
    }

    public getFaqAnswer$(faqId: number): Observable<WucChatbotAnswerInterface> {
        return this.chatbotService.getFaqAnswer$(faqId, this.getSessionId());
    }

    public sendFeedback(feedback: WucChatbotEscalationFeedbackInterface): void {
        this.chatbotService
            .sendFeedback$(this.getSessionId(), this.interactionId, feedback.rating, feedback.comment)
            .pipe(take(1))
            .subscribe();
    }

    public static getClassification(productId: string): { id: number; name: string } {
        switch (productId) {
            case 'AUTO':
                return { id: 289, name: 'autoversicherung' };
            default:
        }
    }

    private saveIds(sessionId: string, interactionId: string): void {
        this.localStorage.setItem(InsChatbotService.LOCALSTORAGE_KEY, sessionId);
        this.interactionId = interactionId;
    }

    private mapToText(answer: ChatbotAnswerInterface): string {
        return answer.elements
            .map((element: ChatbotElementInterface): string => {
                let prefix: string = '';
                switch (element.type) {
                    case 'orderedList':
                        prefix = 'x. ';
                        break;
                    case 'unorderedList':
                        prefix = '- ';
                        break;
                    case 'paragraph':
                        prefix = '';
                        break;
                }
                return element.parts
                    .map((part: ChatbotElementPartInterface, index: number): string => {
                        switch (part.type) {
                            case 'text':
                                return prefix.replace('x', (index + 1).toString()) + part.data;
                            case 'link':
                                const link: WucChatbotLinkInterface = part.data as WucChatbotLinkInterface;
                                return `(link: ${link.text} - ${link.url})`;
                            case 'video':
                                const video: WucChatbotVideoInterface = part.data as WucChatbotVideoInterface;
                                return `(video: ${video.name} - ${video.videoId})`;
                            case 'dialogoption':
                                const dialogoption: WucChatbotDialogOptionInterface =
                                    part.data as WucChatbotDialogOptionInterface;
                                return `(dialogoption: ${dialogoption.text} - ${dialogoption.path})`;
                        }
                    })
                    .join(prefix ? '\r\n' : '');
            })
            .join('\r\n');
    }
}
