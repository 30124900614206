import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WucBalloonModule, WucFormErrorModule, WucFormFieldLabelModule, WucFormFieldModule, WucInputRadioModule } from '@inshared/website-ui-components';
import { InsuranceDeviantDriverFormModule } from './insurance-deviant-driver-form';
import { InsurancePolicyHolderFormModule } from './insurance-policy-holder-form';
import { InsuranceRegularDriverFormComponent } from './insurance-regular-driver-form.component';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
    declarations: [InsuranceRegularDriverFormComponent],
    imports: [
        CommonModule,
        InsurancePolicyHolderFormModule,
        InsuranceDeviantDriverFormModule,
        WucFormFieldModule,
        WucInputRadioModule,
        WucFormFieldLabelModule,
        WucFormErrorModule,
        WucBalloonModule,
        ReactiveFormsModule,
    ],
    exports: [InsuranceRegularDriverFormComponent],
})
export class InsuranceRegularDriverFormModule {
}
