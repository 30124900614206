import { DOCUMENT } from '@angular/common';
import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { PageScrollService } from 'ngx-page-scroll-core';
import { Observable, Subscription } from 'rxjs';
import { WucChatbotAnswerInterface, WucChatbotDialogOptionInterface } from '../chatbot-answer';
import { WucChatbotEscalationFeedbackInterface, WucChatbotEscalationInterface } from '../chatbot-escalation';
import { WucChatbotInputComponent } from '../chatbot-input';
import { WucChatbotListComponent } from '../chatbot-list';

@Component({
    selector: 'wuc-chatbot[buttonText]',
    templateUrl: 'chatbot.component.html',
    styleUrls: ['chatbot.component.scss'],
})
export class WucChatbotComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('input') private inputComponent!: WucChatbotInputComponent;
    @ViewChild('listComponent') private listComponent!: WucChatbotListComponent;
    @ViewChild('escalationComponent') private escalationComponent!: ElementRef;
    @ViewChild('list') private listElement!: ElementRef;
    @ViewChild('componentBottom') private componentBottomElement!: ElementRef;

    @Input() public buttonText!: string;
    @Input() public placeholder: string = '';
    @Input() public maxNumberOfCharacters?: number;
    @Input() public maxNumberOfCharactersText?: string;
    @Input() public maxHeight!: string;
    @Input() public isDialog: boolean = false;
    @Input() public isMobile: boolean = false;
    @Input() public isTablet: boolean = false;
    @Input() public busyText?: string | undefined;
    @Input() public escalation?: WucChatbotEscalationInterface | undefined;
    @Input() public newQuestion$!: Observable<string>;
    @Input() public newAnswer$!: Observable<WucChatbotAnswerInterface>;
    /**
     * @deprecated no need to use this anymore (WSLB-501)
     */
    @Input() public scrollTarget: any;

    @Output() public readonly question: EventEmitter<string> = new EventEmitter<string>();
    @Output() public readonly dialogOption: EventEmitter<WucChatbotDialogOptionInterface> =
        new EventEmitter<WucChatbotDialogOptionInterface>();

    @Output() public readonly feedback: EventEmitter<WucChatbotEscalationFeedbackInterface> =
        new EventEmitter<WucChatbotEscalationFeedbackInterface>();

    public items: (WucChatbotAnswerInterface | string)[] = [];

    public isBusy: boolean = false;

    private subscriptions: Subscription = new Subscription();

    constructor(private pageScrollService: PageScrollService, @Inject(DOCUMENT) private document: Document) {}

    public ngOnInit(): void {
        this.subscriptions.add(
            this.newQuestion$.subscribe((question: string): void => {
                this.items.push(question);
                this.items.push(Object.assign({}, this.busyAnswer));
                this.scrollAnswerToTop();
                this.isBusy = true;
            })
        );
        this.subscriptions.add(
            this.newAnswer$.subscribe((item: WucChatbotAnswerInterface): void => {
                Object.assign(this.items[this.items.length - 1], item);
                this.scrollAnswerToTop();
                this.isBusy = false;
            })
        );
    }

    public ngAfterViewInit(): void {
        this.inputComponent?.setFocus();
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    public onQuestion(question: string): void {
        this.question.emit(question);
    }

    public onFeedback(feedback: WucChatbotEscalationFeedbackInterface): void {
        this.feedback.emit(feedback);
    }

    public onDialogOption(dialogOption: WucChatbotDialogOptionInterface): void {
        this.dialogOption.emit(dialogOption);
    }

    private get busyAnswer(): WucChatbotAnswerInterface {
        return {
            elements: [
                {
                    type: 'paragraph',
                    parts: [
                        {
                            type: 'text',
                            data: this.busyText || '',
                        },
                    ],
                },
            ],
        };
    }

    private scrollAnswerToTop(): void {
        setTimeout(() => this.scrollElementToTop(this.listComponent.lastItemElementRef?.nativeElement), 50);
    }

    public onFeedbackButtonClick(): void {
        this.scrollElementToTop(this.escalationComponent?.nativeElement);
    }

    private scrollElementToTop(element: HTMLElement): void {
        if (element) {
            this.pageScrollService.scroll({
                document: this.document,
                scrollViews: this.maxHeight || this.isDialog ? [this.listElement.nativeElement] : [],
                scrollTarget: element,
                advancedInlineOffsetCalculation: true,
                scrollOffset: 20,
                duration: 500,
            });
        }
    }
}
