import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Data, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, from, Observable } from 'rxjs';
import { map, take, tap, withLatestFrom } from 'rxjs/operators';
import { Coverage, Customer, Insurance, Step } from '../../interfaces';
import { SalesStorageService } from '../../services/sales-storage.service';
import { CoverageService } from '../coverage/coverage.service';
import { CustomerService } from '../customer/customer.service';
import { InsuranceService } from '../insurance/insurance.service';
import * as fromSales from '../sales.reducers';
import { Initialize, InitializeSuccess } from './progress.actions';
import * as fromProgress from './progress.selectors';

@Injectable({
    providedIn: 'root',
})
export class ProgressService {
    public current$: Observable<number> = this.store$.select((fromProgress.getCurrent));
    public productId$: Observable<string> = this.store$.select((fromProgress.getProductId));
    public next$: Observable<Step> = this.store$.select((fromProgress.getNext));
    public previous$: Observable<Step> = this.store$.select((fromProgress.getPrevious));
    public routes$: Observable<string[]> = this.store$.select((fromProgress.getRoutes));
    public index$: Observable<number> = this.store$.select((fromProgress.getRouteIndex));
    public routeUri$: Observable<string> = this.store$.select((fromProgress.getRouteUri));

    public insurance$: Observable<Insurance> = from(this.salesStorageService.getInsurance()).pipe(
        withLatestFrom(this.insuranceService.currentInsuranceData$, this.productId$),
        map(([insuranceFromStorage, insurance, productId]) => {
            if (!insuranceFromStorage) {
                return { ...insurance };
            }
            return { ...insuranceFromStorage[productId], ...insurance };
        }),
    );

    public customer$: Observable<Customer> = from(this.salesStorageService.getCustomer()).pipe(
        withLatestFrom(this.customerService.currentCustomerData$),
        map(([customerFromStorage, customer]) => ({ ...customerFromStorage, ...customer })),
    );

    public isReturningCustomer$: Observable<boolean> = from(this.salesStorageService.isReturningCustomer()).pipe(
        withLatestFrom(this.customerService.isReturningCustomer$),
        map(([fromStorage, isReturningCustomer]) => (isReturningCustomer ? isReturningCustomer : fromStorage)),
    );

    public coverage$: Observable<Coverage[]> = from(this.salesStorageService.getCoverage()).pipe(
        withLatestFrom(this.coverageService.coverages$, this.productId$),
        map(([coverageFromStorage, coverage, productId]) => {
            if (coverage.length > 0) {
                return coverage;
            }

            if (coverageFromStorage && coverageFromStorage[productId]) {
                return coverageFromStorage[productId].coverage || [];
            }

            return [];
        }),
    );

    constructor(
        private store$: Store<fromSales.State>,
        private router: Router,
        private readonly salesStorageService: SalesStorageService,
        private readonly insuranceService: InsuranceService,
        private readonly customerService: CustomerService,
        private readonly coverageService: CoverageService,
    ) {
    }

    public navigateByUrl(url: string): void {
        this.router.navigateByUrl(url);
    }

    public initialize(
        route: ActivatedRouteSnapshot,
        url?: string,
    ): Observable<[Insurance, Customer, boolean, Coverage[]]> {
        const data: Data = route.data;

        this.store$.dispatch(
            new Initialize({
                productId: data.productId,
                steps: data.steps,
                current: 1,
                url,
            }),
        );

        return combineLatest([this.insurance$, this.customer$, this.isReturningCustomer$, this.coverage$]).pipe(
            take(1),
            tap(([insurance, customer, isReturningCustomer, coverage]: [Insurance, Customer, boolean, Coverage[]]) => {
                this.store$.dispatch(
                    new InitializeSuccess({
                        productId: data.productId,
                        insurance,
                        customer,
                        isReturningCustomer,
                        coverage,
                    }),
                );
            }),
        );
    }
}
