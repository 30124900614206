import { Injectable } from '@angular/core';
import { AppRoutesEnum, ReviewIdEnum, ReviewProductNameIdData } from '@inshared/shared/core';
import { ProductIdEnum } from 'outshared-lib';

@Injectable({
    providedIn: 'root',
})
export class ProductIdNameService {

    private static items: ReviewProductNameIdData[] = [
        {
            productId: ProductIdEnum.Accidents,
            reviewId: ReviewIdEnum.AccidentInsurance,
            appRoute: AppRoutesEnum.AccidentInsurance,
            productName: 'ongevallenverzekering',
            oldReviewIds: [1832],
        },
        {
            productId: ProductIdEnum.Bicycle,
            reviewId: ReviewIdEnum.BicycleInsurance,
            appRoute: AppRoutesEnum.BicycleInsurance,
            productName: 'fietsverzekering',
        },
        {
            productId: ProductIdEnum.Building,
            reviewId: ReviewIdEnum.BuildingInsurance,
            appRoute: AppRoutesEnum.BuildingInsurance,
            productName: 'opstalverzekering',
        },
        {
            productId: ProductIdEnum.Car,
            reviewId: ReviewIdEnum.CarInsurance,
            appRoute: AppRoutesEnum.CarInsurance,
            productName: 'autoverzekering',
            oldReviewIds: [1807],
        },
        {
            productId: ProductIdEnum.Caravan,
            reviewId: ReviewIdEnum.CaravanInsurance,
            appRoute: AppRoutesEnum.CaravanInsurance,
            productName: 'caravanverzekering',
            oldReviewIds: [1835],
        },
        {
            productId: ProductIdEnum.Furniture,
            reviewId: ReviewIdEnum.InventoryInsurance,
            appRoute: AppRoutesEnum.InventoryInsurance,
            productName: 'inboedelverzekering',
        },
        {
            productId: ProductIdEnum.Home,
            reviewId: ReviewIdEnum.HomeInsurance,
            appRoute: AppRoutesEnum.HomeInsurance,
            productName: 'woonverzekering',
            oldReviewIds: [1837],
        },
        {
            productId: ProductIdEnum.Legal,
            reviewId: ReviewIdEnum.LegalInsurance,
            appRoute: AppRoutesEnum.LegalInsurance,
            productName: 'rechtsbijstandverzekering',
            oldReviewIds: [1829],
        },
        {
            productId: ProductIdEnum.Liability,
            reviewId: ReviewIdEnum.LiabilityInsurance,
            appRoute: AppRoutesEnum.LiabilityInsurance,
            productName: 'aansprakelijkheidsverzekering',
            oldReviewIds: [1830],
        },
        {
            productId: ProductIdEnum.Moped,
            reviewId: ReviewIdEnum.MopedInsurance,
            appRoute: AppRoutesEnum.MopedInsurance,
            productName: 'brommerverzekering',
            oldReviewIds: [1833],
        },
        {
            productId: ProductIdEnum.Motorcycle,
            reviewId: ReviewIdEnum.MotorInsurance,
            appRoute: AppRoutesEnum.MotorInsurance,
            productName: 'motorverzekering',
            oldReviewIds: [1831],
        },
        {
            productId: ProductIdEnum.Pet,
            reviewId: ReviewIdEnum.PetInsurance,
            appRoute: AppRoutesEnum.PetInsurance,
            productName: 'dierenverzekering',
            oldReviewIds: [1834],
        },
        {
            productId: ProductIdEnum.Travel,
            reviewId: ReviewIdEnum.TravelInsurance,
            appRoute: AppRoutesEnum.TravelInsurance,
            productName: 'reisverzekering',
            oldReviewIds: [1836],
        },
        {
            reviewId: ReviewIdEnum.Damage,
            appRoute: AppRoutesEnum.Damage,
            productName: 'schade',
            oldReviewIds: [4351],
        },
        {
            reviewId: ReviewIdEnum.DamageRepair,
            appRoute: AppRoutesEnum.DamageRepair,
            productName: 'schadeherstel',
            oldReviewIds: [4371],
        },
        {
            reviewId: ReviewIdEnum.InShared,
            appRoute: AppRoutesEnum.Contact,
            productName: 'InShared',
            oldReviewIds: [1374],
        },
        {
            reviewId: ReviewIdEnum.Contact,
            appRoute: AppRoutesEnum.Contact,
            productName: 'InShared',
            oldReviewIds: [1374],
        },
        {
            productId: ProductIdEnum.Scooter,
            reviewId: ReviewIdEnum.ScooterInsurance,
            appRoute: AppRoutesEnum.MopedInsurance,
            productName: 'scooterverzekering',
        },
        {
            reviewId: ReviewIdEnum.MopedSnorInsurance,
            appRoute: AppRoutesEnum.MopedInsurance,
            productName: 'snorfietsverzekering',
        },
        {
            reviewId: ReviewIdEnum.Carglass,
        },
        {
            reviewId: ReviewIdEnum.HerstelDirect,
        },
    ];

    /**
     * Maps a productIdEnum to a ReviewIdEnum
     */
    public static getReviewIdByProductId(productId: ProductIdEnum): ReviewIdEnum {
        const foundData = ProductIdNameService.items.find(
            (item: ReviewProductNameIdData) => item.productId === productId,
        );
        if (!foundData?.reviewId) {
            throw new Error(`No review found for product '${productId}'`);
        }
        return foundData.reviewId;
    }

    // TODO: only used by ActivateContainerComponent which in its place is not used
    /**
     * Maps a productName to a ReviewIdEnum
     */
    public static getReviewIdByProductName(productName: string): ReviewIdEnum {
        const foundData = ProductIdNameService.items.find(
            (item: ReviewProductNameIdData) => item.productName === productName,
        );
        if (!foundData?.reviewId) {
            throw new Error(`No review found for product '${productName}'`);
        }
        return foundData.reviewId;
    }

    /**
     * Maps a productId to a productName
     */
    public static getProductNameByProductId(productId: ProductIdEnum, toUpper: boolean = false): string {
        const foundData = ProductIdNameService.items.find(
            (item: ReviewProductNameIdData) => item.productId === productId,
        );
        if (!foundData?.productName) {
            throw new Error(`No name found for product '${productId}'`);
        }
        return toUpper
            ? ProductIdNameService.capitalizeFirstLetter(foundData.productName)
            : foundData.productName;
    }

    // TODO: ProgressService should return a ProductIdEnum instead of a string
    /**
     * Maps a productName to a productId
     */
    public static getProductNameByProductIdAsString(productId: string, toUpper: boolean = false): string {
        const foundData = ProductIdNameService.items.find(
            (item: ReviewProductNameIdData) => item.productId === productId,
        );
        if (!foundData?.productName) {
            throw new Error(`No name found for product '${productId}'`);
        }
        return toUpper
            ? ProductIdNameService.capitalizeFirstLetter(foundData.productName)
            : foundData.productName;
    }

    /**
     * Maps a reviewId to a productName
     */
    public static getProductNameByReviewId(reviewId: ReviewIdEnum, toUpper: boolean = false): string {
        const foundData = ProductIdNameService.items.find(
            (item: ReviewProductNameIdData) => item.reviewId === reviewId,
        );
        if (!foundData?.productName) {
            throw new Error(`No name found for review '${reviewId}'`);
        }
        return toUpper
            ? ProductIdNameService.capitalizeFirstLetter(foundData.productName)
            : foundData.productName;
    }

    /**
     * Returns the set of data for given reviewId
     */
    public static getDataByReviewId(reviewId: ReviewIdEnum): ReviewProductNameIdData {
        const foundData = ProductIdNameService.items.find(
            (item: ReviewProductNameIdData) => item.reviewId === reviewId,
        );
        if (!foundData) {
            throw new Error(`No data found for review '${reviewId}'`);
        }
        return foundData;
    }

    /**
     * Returns an array containing the given reviewId and possibly an old reviewId
     */
    public static combineWithOldReviewId(reviewId: ReviewIdEnum): number[] {
        const ids: number[] = [reviewId];
        const foundData = ProductIdNameService.getDataByReviewId(reviewId);
        if (foundData?.oldReviewIds) {
            ids.push(...foundData.oldReviewIds);
        }
        return ids;
    }

    public static capitalizeFirstLetter(name: string): string {
        return `${name.charAt(0).toUpperCase()}${name.slice(1)}`;
    }

    /**
     * Retrieves the review IDs associated with a given product ID.
     * This includes the current review ID and any old review IDs.
     *
     * @param productId - The ID of the product for which to retrieve review IDs.
     * @returns An array of review IDs associated with the given product ID.
     * @throws An error if no data is found for the given product ID.
     */
    public static getReviewIdsByProductId(productId: string): number[] {
        const itemByProductId = this.items.find((item: ReviewProductNameIdData) => item.productId === productId);
        if (!itemByProductId) {
            throw new Error(`No data found for product '${productId}'`);
        }
        return [itemByProductId.reviewId, ...(itemByProductId?.oldReviewIds || [])];
    }
}
