import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NewModalModule } from 'outshared-lib';
import { QuestionModal } from './question.modal';
import { QuestionModalContainer } from './question.modal.container';
import { ModalComponentsModule } from '@app-de/components';
import { ListModule } from '@app-de/shared/lists/list.module';

@NgModule({
    imports: [
        CommonModule,
        NewModalModule,
        ListModule,
        ModalComponentsModule,
    ],
    exports: [
        QuestionModal,
        QuestionModalContainer,
    ],
    declarations: [
        QuestionModal,
        QuestionModalContainer,
    ],
})
export class QuestionModalModule {}
