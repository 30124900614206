import { Component, Input, OnDestroy, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import {
    WucChatbotAnswerInterface,
    WucChatbotDialogOptionInterface,
    WucChatbotEscalationChannelInterface,
    WucChatbotEscalationFeedbackInterface,
    WucChatbotEscalationInterface,
} from '@inshared/website-ui-components';
import {
    AuthenticationDataService,
    ChatbotAnswerInterface,
    NewModal,
    RelationDataService,
    RelationInterface,
} from 'outshared-lib';
import { Observable, ReplaySubject, Subject, Subscription } from 'rxjs';
import { delay, switchMap } from 'rxjs/operators';
import { AppConfigService } from '@app-de/core/configuration';
import { LivechatService } from '@app-de/core/livechat/livechat.service';
import { InsChatbotService } from '@app-de/shared/customer-service/chatbot';
import { IsCustomerModalComponent } from '@app-de/shared/modals/is-customer';
import { NativeRouterService } from '@inshared/shared/util';
import { FaqRoutingEnum } from '@inshared/shared/core';
import { AppRoutesEnum } from '@app-de/routes';
import { MyZoneRoutes } from '@app-de/my-zone';

@Component({
    selector: 'ins-chatbot-container',
    templateUrl: './chatbot.container.html',
})
export class ChatbotContainerComponent implements OnInit, OnDestroy {
    private appConfig = inject(AppConfigService).getConfig();

    @Input() public question: string;
    @Input() public isDialog: boolean = false;
    @Input() public isMobile: boolean = false;
    @Input() public isTablet: boolean = false;
    @Input() public maxHeight: string;
    @Input() public scrollTarget: 'list' | 'input' = 'list';

    public authenticated$: Observable<boolean> = this.authenticationDataService.isLoggedIn$();
    public relation$: Observable<RelationInterface> = this.relationDataService.getRelation$();
    public subscriptions: Subscription = new Subscription();
    public newQuestion$: ReplaySubject<string> = new ReplaySubject<string>();
    public newAnswer$: ReplaySubject<WucChatbotAnswerInterface> = new ReplaySubject<WucChatbotAnswerInterface>();
    public baseUrl = inject(NativeRouterService).baseUrl;

    public escalation?: WucChatbotEscalationInterface;

    private excludeQueryParamQuestions: string[] = ['ikweethetkentekenniet'];
    private questionRequest$: Subject<{ question: string; dialogPath?: string }> = new Subject<{
        question: string;
        dialogPath?: string;
    }>();

    constructor(
        private chatbotService: InsChatbotService,
        private router: Router,
        private route: ActivatedRoute,
        private readonly relationDataService: RelationDataService,
        private livechatService: LivechatService,
        private newModal: NewModal,
        private readonly authenticationDataService: AuthenticationDataService,
    ) {}

    public ngOnInit(): void {
        this.subscriptions.add(
            this.questionRequest$
                .asObservable()
                .pipe(
                    switchMap(
                        (question: { question: string; dialogPath?: string }): Observable<WucChatbotAnswerInterface> =>
                            this.chatbotService.askQuestion$(question.question, question.dialogPath),
                    ),
                    delay(1000),
                )
                .subscribe((response: ChatbotAnswerInterface): void => {
                    this.handleAnswer(response);
                }),
        );

        this.subscriptions.add(
            this.route.queryParams.subscribe((params: Params): void => {
                if (params.q && !this.excludeQueryParamQuestions.includes(params.q)) {
                    this.onQuestion(params.q);
                }
            }),
        );

        if (this.question) {
            setTimeout((): void => this.onQuestion(this.question));
        }
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    public onQuestion(question: string, dialogPath?: string): void {
        this.escalation = null;
        this.questionRequest$.next({ question, dialogPath });
        this.newQuestion$.next(question);
    }

    public onDialogOption(dialogOption: WucChatbotDialogOptionInterface): void {
        this.onQuestion(dialogOption.text, dialogOption.path);
    }

    public onFeedback(feedback: WucChatbotEscalationFeedbackInterface): void {
        this.chatbotService.sendFeedback(feedback);
    }

    public onNavigateAway(): void {
        this.newModal.closeAll();
    }

    private handleAnswer(answer: ChatbotAnswerInterface): void {
        this.newAnswer$.next(answer);

        const isLivechatEnabled = this.appConfig.enabledFeatures.livechat;
        const channels: WucChatbotEscalationChannelInterface[] = [];

        /**
         * Feature toggle: WIDL-2515
         * Livechat can be enabled in config.json,
         * if livechat is in production feature toggle can be removed.
         * */
        if (isLivechatEnabled && answer.escalation.showChatDE) {
            channels.push({
                icon: 'chat',
                title: 'Über livechat',
                subTitle: 'Chatten Sie direkt mit einem Mitarbeiter',
                callback: (): void => this.showChat(),
            });
        }
        if (answer.escalation.showVerzekeringsmap) {
            channels.push({
                icon: 'current-insurance',
                title: 'Ihre Online-Versicherungsmappe',
                subTitle: 'Hier regeln Sie (fast) alles selbst!',
                routerLink: `${AppRoutesEnum.MyZone}/${MyZoneRoutes.MyInsurances}`,
            });
        }
        if (answer.escalation.showFacebook) {
            channels.push({
                icon: 'facebook',
                title: 'Über Facebook',
                subTitle: 'Reagieren wir spätestens nach 1 Arbeitstag',
                url: 'https://www.facebook.com/messages/t/user:145298522187072',
            });
        }
        if (answer.escalation.showTwitter) {
            channels.push({
                icon: 'twitter',
                title: 'Via X',
                subTitle: 'Reagieren wir spätestens nach 1 Arbeitstag',
                url: 'https://x.com/inshared',
            });
        }
        if (answer.escalation.showEmail) {
            channels.push({
                icon: 'mail',
                title: 'Über E-Mail',
                subTitle: 'Binnen 5 Arbeitstagen. Fragen zur SF-Klasse binnen 10 Arbeitstagen.',
                callback: () => location.href = "mailto:"+'kontakt@inshared.de',
            });
        }
        if (answer.escalation.showSchadeteamMail) {
            channels.push({
                icon: 'mail',
                title: 'Senden Sie unserem Schadenteam eine E-Mail',
                subTitle: 'Binnen 5 Arbeitstagen erhalten Sie eine Reaktion',
                callback: (): Promise<boolean> => this.router.navigate(['', { outlets: { modal: ['vraag', 'SCHD'] } }]),
            });
        }
        if (answer.escalation.showSchadeteamTel) {
            channels.push({
                icon: 'phone',
                subTitle: '+ 49 221 8277 9952',
                title: 'Rufen Sie unser Schadenteam an:',
                url: 'tel:+4922182779952',
            });
        }

        // No longer in use?
        // if (answer.escalation.showKlachtenmanMail) {}
        // if (answer.escalation.showSchadeteamChat) {}
        // if (answer.escalation.showSchadeteamFacebook) {}
        // if (answer.escalation.showSchadeteamTwitter) {}

        if (channels.length) {
            this.escalation = {
                usefulness: {
                    title: 'War diese Antwort hilfreich?',
                    buttonYesText: 'Ja, absolut',
                    buttonNoText: 'Leider nicht',
                },
                thanksText: 'Vielen Dank für Ihr Feedback',
                channels: [
                    {
                        mainTitle: 'Häufig gestellte Fragen',
                        title: 'Häufig gestellte Fragen anzeigen',
                        subTitle: 'Hier finden Sie die am häufigsten gestellten Fragen',
                        icon: 'search',
                        directLink: {
                            url: `${this.baseUrl}${FaqRoutingEnum.Faq}`,
                        },
                    },
                    {
                        title: 'Kontakt',
                        items: channels,
                    },
                ],
            };
        } else {
            this.escalation = null;
        }
    }

    private showChat(): void {
        this.subscriptions.add(
            this.authenticated$.subscribe((isAuthenticated): void => {
                if (isAuthenticated) {
                    this.newModal.closeAll();
                    this.livechatService.init(isAuthenticated);
                } else {
                    this.newModal.closeAll();
                    this.newModal.open(IsCustomerModalComponent);
                }
            }),
        );
    }
}
