import { ViewportScroller } from '@angular/common';
import { NgModule } from '@angular/core';
import { Router, RouterModule, Routes } from '@angular/router';
import { DisclaimerModalModule } from '@app-de/modals/disclaimer-modal/disclaimer.modal.module';
import { ResponseTimesModalModule } from '@app-de/modals/response-times-modal/response-times.modal.module';
import { MyZoneRoutes } from '@app-de/my-zone';
import { AppRoutesEnum } from '@app-de/routes';
import { accountRecoveryMatcher } from '@app-de/routing/account-recovery-modal.matcher';
import { AuthenticateGuard } from '../authentication/authenticate.guard';
import { DisableScrollToTopUtility } from '../core/utilities/disable-scroll-to-top.utility';
import { salesfunnelUrlMatcher } from './salesfunnel.matcher';

const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('../pages/index/index.module').then((m) => m.IndexModule),
    },
    {
        path: AppRoutesEnum.Insurances,
        loadChildren: () => import('../pages/insurances/insurances.module').then((m) => m.InsurancesModule),
    },
    {
        path: AppRoutesEnum.CarInsurance,
        loadChildren: () =>
            import('../pages/car-insurance/autoversicherung/autoversicherung.module').then(
                (m) => m.AutoversicherungModule,
            ),
    },
    {
        path: AppRoutesEnum.About,
        loadChildren: () => import('../pages/about-inshared/uber-inshared.module').then((m) => m.UberInsharedModule),
    },
    {
        path: AppRoutesEnum.KfzVersicherung,
        loadChildren: () =>
            import('../pages/car-insurance/kfz-versicherung/kfz-versicherung.module').then(
                (m) => m.KfzVersicherungModule,
            ),
    },
    {
        path: AppRoutesEnum.Privacy,
        loadChildren: () => import('../pages/privacy/privacy.module').then((m) => m.PrivacyModule),
    },
    {
        path: AppRoutesEnum.Payment,
        loadChildren: () =>
            import('../pages/payment-process/payment-process.module').then((m) => m.PaymentProcessModule),
    },
    {
        path: AppRoutesEnum.AnnualReward,
        loadChildren: () => import('../pages/annual-reward/annual-reward.module').then((m) => m.AnnualRewardModule),
    },
    {
        path: AppRoutesEnum.Login,
        loadChildren: () => import('../pages/login-page/login-page.module').then((m) => m.LoginPageModule),
    },
    {
        path: AppRoutesEnum.Logout,
        loadChildren: () => import('../pages/logout/logout.module').then((m) => m.LogoutModule),
    },
    {
        path: AppRoutesEnum.Cookies,
        loadChildren: () => import('../pages/cookies/cookies.module').then((m) => m.CookiesModule),
    },
    {
        path: '',
        loadChildren: () => import('../pages/contact/contact.module').then((m) => m.ContactModule),
    },
    {
        path: AppRoutesEnum.OptOut,
        loadChildren: () => import('../pages/opt-out/opt-out.module').then((m) => m.OptOutModule),
    },
    {
        path: AppRoutesEnum.Damage,
        loadChildren: () => import('../pages/damage/damage.module').then((m) => m.DamageModule),
    },
    {
        matcher: salesfunnelUrlMatcher,
        loadChildren: () => import('../salesfunnel/salesfunnel.module').then((m) => m.SalesfunnelModule),
    },
    {
        path: AppRoutesEnum.MyZone,
        canActivate: [AuthenticateGuard],
        loadChildren: () => import('../my-zone/my-zone.module').then((m) => m.MyZoneModule),
    },
    {
        matcher: accountRecoveryMatcher,
        loadChildren: () => import('@app-de/account-recovery').then((module) => module.AccountRecoveryModule),
    },

    // 404
    {
        path: '**',
        data: {
            title: '404',
            doNotSetCanonical: true,
        },
        loadChildren: () => import('../pages/page-not-found/page-not-found.module').then((m) => m.PageNotFoundModule),
    },
];

@NgModule({
    imports: [
        ResponseTimesModalModule,
        DisclaimerModalModule,
        RouterModule.forRoot(routes, {
            useHash: false,
            enableTracing: false,
            initialNavigation: 'enabledBlocking',
            onSameUrlNavigation: 'reload',
            scrollPositionRestoration: 'disabled',
        }),
    ],
    exports: [RouterModule],
})
export class RoutingModule {
    constructor(router: Router, viewportScroller: ViewportScroller) {
        const urls = [
            `/${AppRoutesEnum.MyZone}/${MyZoneRoutes.MyInsurances}`,
            `/${AppRoutesEnum.MyZone}/${MyZoneRoutes.Details}`,
        ];
        // Disable scroll to top for given urls
        DisableScrollToTopUtility.disable(urls, router, viewportScroller);
    }
}
