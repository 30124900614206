<footer class="footer">
    <div class="container">
        <div class="footer__external">
            <section class="footer__external-left">
                <p class="h4 text text--primary">Wir sind InShared</p>
                <p>
                    Und wir machen alles ein bisschen anders. Gemeinsam wird Versichern zu einer fairen Sache. We
                    all benefit.
                </p>
            </section>
            <section class="footer__external-right">
                <wuc-picture
                    insTooltip="Zertifizierte Cloud-Sicherheit (Stufe 2): Ihre Daten sind bei uns sicher!"
                    imageDesktop="assets/shared/CSA-Star&#64;2x.webp"
                    imageMobile="assets/shared/CSA-Star-icon.webp"
                    [imageWidth]="{ desktop: 133, mobile: 53 }"
                    [imageHeight]="{ desktop: 100, mobile: 40 }"
                    imageType="image/webp"
                    alt="Zertifizierte Cloud-Sicherheit (Stufe 2): Ihre Daten sind bei uns sicher!"
                ></wuc-picture>
                <div class="footer__social">
                    <p class="h5 footer__social-title">InShared folgen</p>
                    <svg class="footer__social-arrow svg-icon svg-icon--xl">
                        <use href="assets/icons-general.svg#pijlRR"></use>
                    </svg>
                    <ul class="footer__social-list">
                        <li class="list-group-item">
                            <a
                                href="https://www.facebook.com/inshared.de/"
                                class="footer__social-link"
                                alt="Facebook"
                                title="Facebook"
                                rel="noopener"
                                target="_blank"
                            >
                                <svg class="footer__social-icon svg-icon svg-icon--xl">
                                    <use href="assets/icons-general.svg#facebook"></use>
                                </svg>
                            </a>
                        </li>
                        <li class="list-group-item">
                            <a
                                href="https://www.instagram.com/inshared.de/"
                                class="footer__social-link"
                                alt="Instagram"
                                title="Instagram"
                                rel="noopener"
                                target="_blank"
                            >
                                <svg class="footer__social-icon svg-icon svg-icon--xl">
                                    <use href="assets/icons-general.svg#instagram"></use>
                                </svg>
                            </a>
                        </li>
                    </ul>
                </div>
            </section>
        </div>

        <section class="footer__navigation">
            <ul class="footer__navigation-list">
                <li class="footer__navigation-item">
                    <a [routerLink]="['/', appRoutesEnum.Insurances]">
                        Versicherungen
                        <svg class="svg-icon svg-icon--xs">
                            <use href="assets/icons-ui.svg#pijl-right-bold"></use>
                        </svg>
                    </a>
                </li>
                <li class="footer__navigation-item">
                    <a [routerLink]="['/', appRoutesEnum.KfzVersicherung]">
                        Kfz-Versicherung
                        <svg class="svg-icon svg-icon--xs">
                            <use href="assets/icons-ui.svg#pijl-right-bold"></use>
                        </svg>
                    </a>
                </li>
                <li class="footer__navigation-item">
                    <a [routerLink]="['/', appRoutesEnum.CarInsurance]">
                        Autoversicherung
                        <svg class="svg-icon svg-icon--xs">
                            <use href="assets/icons-ui.svg#pijl-right-bold"></use>
                        </svg>
                    </a>
                </li>
                <li class="footer__navigation-item">
                    <a [routerLink]="['/', appRoutesEnum.CarInsurance, autoversicherungRoutesEnum.Vollkasko]">
                        Vollkasko
                        <svg class="svg-icon svg-icon--xs">
                            <use href="assets/icons-ui.svg#pijl-right-bold"></use>
                        </svg>
                    </a>
                </li>
                <li class="footer__navigation-item">
                    <a [routerLink]="['/', appRoutesEnum.CarInsurance, autoversicherungRoutesEnum.Fahrerschutz]">
                        Fahrerschutz
                        <svg class="svg-icon svg-icon--xs">
                            <use href="assets/icons-ui.svg#pijl-right-bold"></use>
                        </svg>
                    </a>
                </li>
                <li class="footer__navigation-item">
                    <a [routerLink]="['/', appRoutesEnum.CarInsurance, autoversicherungRoutesEnum.Pannenhilfe]">
                        Pannenhilfe
                        <svg class="svg-icon svg-icon--xs">
                            <use href="assets/icons-ui.svg#pijl-right-bold"></use>
                        </svg>
                    </a>
                </li>
                <li class="footer__navigation-item">
                    <a [routerLink]="['/', appRoutesEnum.About]">
                        Über InShared
                        <svg class="svg-icon svg-icon--xs">
                            <use href="assets/icons-ui.svg#pijl-right-bold"></use>
                        </svg>
                    </a>
                </li>
                <li class="footer__navigation-item">
                    <a [routerLink]="['/', appRoutesEnum.AnnualReward]">
                        Jahresbelohnung
                        <svg class="svg-icon svg-icon--xs">
                            <use href="assets/icons-ui.svg#pijl-right-bold"></use>
                        </svg>
                    </a>
                </li>
                <li class="footer__navigation-item">
                    <a [routerLink]="['/', appRoutesEnum.Contact]">
                        Ihre Frage
                        <svg class="svg-icon svg-icon--xs">
                            <use href="assets/icons-ui.svg#pijl-right-bold"></use>
                        </svg>
                    </a>
                </li>
                <li class="footer__navigation-item">
                    <a [routerLink]="['/', appRoutesEnum.Login]">
                        Einloggen
                        <svg class="svg-icon svg-icon--xs">
                            <use href="assets/icons-ui.svg#pijl-right-bold"></use>
                        </svg>
                    </a>
                </li>
                <li class="footer__navigation-item">
                    <a [routerLink]="['/', appRoutesEnum.Damage]">
                        Schäden
                        <svg class="svg-icon svg-icon--xs">
                            <use href="assets/icons-ui.svg#pijl-right-bold"></use>
                        </svg>
                    </a>
                </li>
                <li class="footer__navigation-item">
                    <a
                        [routerLink]="[
                            '/',
                            appRoutesEnum.About,
                            appRoutesEnum.EverythingOnline,
                            allesOnlineRoutes.SignUp
                        ]"
                    >
                        Kunde werden
                        <svg class="svg-icon svg-icon--xs">
                            <use href="assets/icons-ui.svg#pijl-right-bold"></use>
                        </svg>
                    </a>
                </li>
                <li class="footer__navigation-item">
                    <a [routerLink]="['/', appRoutesEnum.About, appRoutesEnum.EverythingOnline]">
                        Alles online
                        <svg class="svg-icon svg-icon--xs">
                            <use href="assets/icons-ui.svg#pijl-right-bold"></use>
                        </svg>
                    </a>
                </li>
                <li class="footer__navigation-item">
                    <a [routerLink]="['/', appRoutesEnum.Privacy]">
                        Datenschutz (Webseite)
                        <svg class="svg-icon svg-icon--xs">
                            <use href="assets/icons-ui.svg#pijl-right-bold"></use>
                        </svg>
                    </a>
                </li>
                <li class="footer__navigation-item">
                    <a [routerLink]="['/', appRoutesEnum.Cookies]">
                        Cookies
                        <svg class="svg-icon svg-icon--xs">
                            <use href="assets/icons-ui.svg#pijl-right-bold"></use>
                        </svg>
                    </a>
                </li>
                <li class="footer__navigation-item">
                    <a
                        [href]="'/' + appRoutesEnum.Contact + '/(modal:cookie-settings)'"
                        rel="noindex nofollow"
                    >
                        Cookie-Einstellungen
                        <svg class="svg-icon svg-icon--xs">
                            <use href="assets/icons-ui.svg#pijl-right-bold"></use>
                        </svg>
                    </a>
                </li>
                <li class="footer__navigation-item">
                    <a
                        [routerLink]="[
                            '/',
                            appRoutesEnum.Contact,
                            { outlets: { modal: modalRoutesEnum.Disclaimer, primary: null } }
                        ]"
                        rel="noindex nofollow"
                    >
                        Impressum
                        <svg class="svg-icon svg-icon--xs">
                            <use href="assets/icons-ui.svg#pijl-right-bold"></use>
                        </svg>
                    </a>
                </li>
                <li class="footer__navigation-item">
                    <a
                        [routerLink]="[
                            '/',
                            appRoutesEnum.Contact,
                            { outlets: { modal: modalRoutesEnum.ResponseTimes, primary: null } }
                        ]"
                        rel="noindex nofollow"
                    >
                        Reaktionszeiten
                        <svg class="svg-icon svg-icon--xs">
                            <use href="assets/icons-ui.svg#pijl-right-bold"></use>
                        </svg>
                    </a>
                </li>
            </ul>
        </section>
        <div class="footer__contact">
            <wuc-picture
                imageDesktop="/assets/shared/inshared-achmea.svg"
                [imageWidth]="{ desktop: 300 }"
                [imageHeight]="{ desktop: 30 }"
                imageType="image/svg+xml"
                alt="InShared gehört zur Achmea-Gruppe"
            ></wuc-picture>
        </div>
    </div>
</footer>
