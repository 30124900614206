import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { DateService } from '../../../core';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { CookieStorage } from '../../../../../shared/util-storage/src';

interface CookieOptionsInterface {
    path?: string;
    domain?: string;
    secure?: boolean;
    expires?: number;
}

/**
 * @deprecated use other storageService
 * We try to limit the dependencies of DatalayerDeprecated service
 */
export const StorageService: InjectionToken<Storage> = new InjectionToken<Storage>('StorageService');

/**
 * @deprecated DatalayerCookieStorage.
 *  We created a local DatalayerCookieStorage for our datalayer.
 *  We try to limit the dependencies for the datalayer and outshared-lib
 *  to make it more generic, easy to implement and prevent circular dependencies.
 */
@Injectable({
    providedIn: 'root',
})
export class DatalayerCookieStorage implements Storage {
    [index: number]: string;

    [key: string]: any;
    private cookieDomain: string;
    private cookieSetting: any;

    length: number;

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private cookieService: CookieStorage,
        private dateService: DateService
    ) {
        if (this.document.location.hostname === 'localhost') {
            this.cookieDomain = 'localhost';
        } else {
            this.cookieDomain = `.${this.document.location.hostname}`;
        }
    }

    public clear(): void {
        this.cookieService.clear();
    }

    public getItem(key: string): string {
        return this.cookieService.getItem(key);
    }

    public key(index: number): string {
        return this.cookieService.key(index);
    }

    public removeItem(key: string, options?: CookieOptionsInterface): void {
        this.cookieService.removeItem(key, options);
    }

    public setItem(key: string, data: string, options?: CookieOptionsInterface): void {
       this.cookieService.setItem(key, data, options);
    }

    public getObject(key: string): Object {
        return this.cookieService.getObject(key);
    }

    public setObject(key: string, value: object, options?: CookieOptionsInterface): void {
        this.cookieService.setObject(key, value, options);
    }
}
