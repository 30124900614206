import { DOCUMENT, isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID, Renderer2, RendererFactory2 } from '@angular/core';
import { CookieConsent } from 'outshared-lib';
import { AppConfigService } from '../configuration';
import { CookieStorage } from '@inshared/shared/util-storage';

@Injectable({
    providedIn: 'root',
})
export class BlueConicService {
    public cookieSettingsEnum = CookieConsent;

    private renderer: Renderer2;
    private blueconicUrl: string;

    constructor(
        private rendererFactory: RendererFactory2,
        private cookieStorage: CookieStorage,
        private appConfigService: AppConfigService,
        @Inject(DOCUMENT) private document: Document,
        @Inject(PLATFORM_ID) private platformId: Object,
    ) {
        this.renderer = this.rendererFactory.createRenderer(null, null);
    }

    public init(): void {
        this.blueconicUrl = this.appConfigService.getConfig().blueconicUrl;

        if (isPlatformServer(this.platformId) || !Boolean(this.blueconicUrl)) {
            return;
        }

        if (!this.hasPermissions() || this.hasBlueconicScript()) {
            return;
        }

        this.addScript();
    }

    public removeScript(): void {
        const element = this.getBlueconicScript();

        if (element === null) {
            return;
        }

        element.parentNode.removeChild(element);
    }

    private addScript(): void {
        const csa = this.renderer.createElement('script');

        this.renderer.setAttribute(csa, 'type', 'text/javascript');
        this.renderer.setAttribute(csa, 'src', this.blueconicUrl);
        this.renderer.setAttribute(csa, 'id', 'blueconicScript');

        this.document.getElementsByTagName('head')[0].appendChild(csa);
    }

    private getBlueconicScript(): HTMLElement {
        return this.document.getElementById('blueconicScript');
    }

    private hasBlueconicScript(): boolean {
        return Boolean(this.getBlueconicScript());
    }

    private hasPermissions(): boolean {
        // cookiebar nog niet gezien, dus geen toestemming
        if (!this.cookieStorage.getItem('ls.cookie.setting')) {
            return false;
        }

        // gekozen voor all, dus wel toestemming
        return this.cookieStorage.getItem('ls.cookie.setting') === this.cookieSettingsEnum.All;
    }
}
