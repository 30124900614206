import { ApiProductIdEnum } from 'outshared-lib';

export const productSchemaByProductID: Partial<
    Record<ApiProductIdEnum, { name: string; description: string; }>> = {
        [ApiProductIdEnum.Car]: {
            name: $localize`:@@seo.product.structured-data.car.name:Autoverzekering`,
            description: $localize`:@@seo.product.structured-data.car.description:Uw auto goed en voordelig verzekeren? De Autoverzekering van InShared is een van de goedkoopste. Simpel en snel`,
        },
        [ApiProductIdEnum.Bicycle]: {
            name: $localize`:@@seo.product.structured-data.bicycle.name:Fietsverzekering`,
            description: $localize`:@@seo.product.structured-data.bicycle.description:Met de Fietsverzekering van InShared zijn uw fiets, accu en accessoires goed en voordelig verzekerd tegen diefstal.`,
        },
        [ApiProductIdEnum.Moped]: {
            name: $localize`:@@seo.product.structured-data.moped.name:Bromfietsverzekering`,
            description: $localize`:@@seo.product.structured-data.moped.description:Uw bromfiets goed en voordelig verzekeren? De InShared Brommerverzekering is een van de goedkoopste. Simpel en snel online afsluiten.`,
        },
        [ApiProductIdEnum.Caravan]: {
            name: $localize`:@@seo.product.structured-data.caravan.name:Caravanverzekering`,
            description: $localize`:@@seo.product.structured-data.caravan.description:Uw caravan goed verzekeren? De Caravanverzekering van InShared is een van de goedkoopste. Simpel en snel online afsluiten.`,
        },
        [ApiProductIdEnum.Motorcycle]: {
            name: $localize`:@@seo.product.structured-data.motorcycle.name:Motorverzekering`,
            description: $localize`:@@seo.product.structured-data.motorcycle.description:Uw motor verzekeren? Met de InShared Motorverzekering bent u uitstekend én voordelig verzekerd. Simpel en snel online afsluiten.`,
        },
        [ApiProductIdEnum.Travel]: {
            name: $localize`:@@seo.product.structured-data.travel.name:Reisverzekering`,
            description: $localize`:@@seo.product.structured-data.travel.description:Met de Reisverzekering van InShared is uw vakantie goed en voordelig verzekerd. Sluit de Reisverzekering simpel en direct online af.`,
        },
        [ApiProductIdEnum.Pet]: {
            name: $localize`:@@seo.product.structured-data.pet.name:Dierenverzekering`,
            description: $localize`:@@seo.product.structured-data.pet.description:Uw hond of kat is goed verzekerd voor medische kosten met de Dierenverzekering van InShared. Bereken snel en eenvoudig uw premie en reken op de beste dekking.`,
        },
        [ApiProductIdEnum.Home]: {
            name: $localize`:@@seo.product.structured-data.home.name:Inboedelverzekering`,
            description: $localize`:@@seo.product.structured-data.home.description:Uw inboedel voordelig verzekeren? De Inboedelverzekering van InShared is een van de goedkoopste. Simpel en snel online afsluiten`,
        },
};
