<div
  class="wuc-input-text wuc-input-container wuc-input-container--border"
  [class.wuc-input-container--has-error]="hasError"
>
  <input
    #input
    type="text"
    class="wuc-input"
    [class.input--error]="hasError"
    [class.input--placeholder-small]="isPlaceholderSmall"
    [attr.autocomplete]="autocomplete"
    [attr.inputmode]="inputmode"
    [attr.aria-label]="ariaLabel"
    [attr.id]="name ?? null"
    [attr.e2e]="e2e ?? null"
    [attr.maxlength]="restrictMaxNumberOfCharacters && maxNumberOfCharacters ? maxNumberOfCharacters : null"
    [placeholder]="placeholder"
    [(ngModel)]="value"
    (input)="onChange(input.value)"
    (blur)="onBlur(input)"
    [disabled]="isDisabled"
  />
  <div
    class="wuc-input-text__chars"
    [class.wuc-input-text__chars--position-outside]="maxNumberOfCharactersPositionOutside"
  >
    <div
      *ngIf="value && maxNumberOfCharacters"
      class="wuc-input-text__chars-remaining-count"
      [class.wuc-input-text__chars-remaining-count-low]="remainingCount <= (warnNumberOfCharacters ?? 10)"
    >
      {{ remainingCount }}
    </div>
    <div *ngIf="!value && maxNumberOfCharactersText" class="wuc-input-text__max-chars-info">
      {{ maxNumberOfCharactersText }}
    </div>
  </div>
</div>
