import { Inject, Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { StorageService } from '@inshared/shared/util-storage';

@Injectable({
    providedIn: 'root',
})
export class InsCookieService {

    public static cookieConsentSubject$: ReplaySubject<boolean> = new ReplaySubject<boolean>();
    public static cookieConsent$: any = InsCookieService.cookieConsentSubject$;

    // eslint-disable-next-line no-useless-constructor
    constructor(
        @Inject(StorageService) private storage: Storage,
    ) {
    }

    public init(): void {
        const hasCompleetCookies = this.storage.getItem('ls.cookie.setting') === 'all';
        InsCookieService.cookieConsentSubject$.next(hasCompleetCookies);
    }
}
