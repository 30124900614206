/**
* Temporailiy enum for /faq navigation links.
* Will be replaced/refactored when faq content is moved to new Angular.
**/
export enum FaqRoutingEnum {
    Faq = '/faq',
    DeTienMeestGesteldeVragen = '/faq/de-tien-meest-gestelde-vragen',
    GegevensWijzigen = '/faq/iets-veranderd/gegevens-wijzigen',
    Jaarbeloning = '/faq/jaarbeloning/jaarbeloning',
    IsEenAutoverzekeringWettelijkVerplicht = '/faq/lopende-verzekering/autoverzekering/is-een-autoverzekering-wettelijk-verplicht',
    WatIsHetEigenRisicoBijDeWaDekking = '/faq/lopende-verzekering/autoverzekering/wat-is-het-eigen-risico-bij-de-wa-dekking',
    LopendeVerzekeringGegevensWijzigen = '/faq/lopende-verzekering/gegevens-wijzigen',
    Aansprakelijkheidsverzekering = '/faq/nieuwe-verzekering/aansprakelijkheidsverzekering',
    Autoverzekering = '/faq/nieuwe-verzekering/autoverzekering',
    Brommerverzekering = '/faq/nieuwe-verzekering/brommerverzekering',
    Dierenverzekering = '/faq/nieuwe-verzekering/dierenverzekering',
    KlantWorden = '/faq/nieuwe-verzekering/klant-worden',
    PremieBetalen = '/faq/nieuwe-verzekering/premie-betalen',
    Rechtsbijstandverzekering = '/faq/nieuwe-verzekering/rechtsbijstandverzekering',
    Reisverzekering = '/faq/nieuwe-verzekering/reisverzekering',
    Woonverzekering = '/faq/nieuwe-verzekering/woonverzekering',
    Schademelden = '/faq/schade/schademelden',
    HebIkRechtOpVervangendVervoer = '/faq/schade/schademelden/heb-ik-recht-op-vervangend-vervoer',
}
