import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AddressAutocompleteFieldComponent } from './address-autocomplete-field/address-autocomplete-field.component';
import { AddressAutocompleteInputComponent } from './address-autocomplete-input/address-autocomplete-input.component';
import { AddressAutocompleteLabelComponent } from './address-autocomplete-label/address-autocomplete-label.component';
import { AddressAutocompleteComponent } from './address-autocomplete.component';
import { AddressAutocompleteContainer } from '@app-de/shared/address-autocomplete/address-autocomplete.container';
import { HighlightModule } from '@app-de/shared/pipes/highlight/highlight.module';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
    declarations: [
        AddressAutocompleteComponent, AddressAutocompleteContainer,
        AddressAutocompleteFieldComponent, AddressAutocompleteInputComponent,
        AddressAutocompleteLabelComponent,
    ],
    exports: [
        AddressAutocompleteContainer,
        AddressAutocompleteFieldComponent,
        AddressAutocompleteInputComponent,
        AddressAutocompleteLabelComponent,
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        HighlightModule,
    ],
})
export class AddressAutocompleteModule {}
