import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NewModalModule } from 'outshared-lib';
import { ChatbotModule } from '../chatbot/chatbot.module';
import { ChatbotModalComponent } from './chatbot-modal.component';
import { ModalComponentsModule } from '@app-de/components';


@NgModule({
    declarations: [ChatbotModalComponent],
    imports: [CommonModule, NewModalModule, ChatbotModule, ModalComponentsModule],
})
export class ChatbotModalModule {}
