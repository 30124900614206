import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { NewModal } from 'outshared-lib';
import { Subscription } from 'rxjs';
import { RouteHelperService } from '@app-de/core/services';
import { ModalRoutesEnum } from '@app-de/routes';
import { CookieSettingsService } from '@inshared/shared/feature-cookie';

@Component({
    selector: 'ins-routed-modal',
    templateUrl: 'routed.modal.html',
})
export class RoutedModal implements AfterViewInit, OnDestroy {
    private subscriptions = new Subscription();

    public get currentUrl(): string {
        return this.routeHelperService.getCleanUrl();
    }

    constructor(
        private modalService: NewModal,
        private router: Router,
        private route: ActivatedRoute,
        private routeHelperService: RouteHelperService,
        private cookieSettingsService: CookieSettingsService,
    ) {}

    public ngAfterViewInit(): void {
        const modalComponent = this.route.snapshot.data.component;
        const context: string = this.route.snapshot.data.context;

        // this line makes sure only one routed modal is open at the time
        this.modalService.closeAll();

        if (this.router.url.includes(ModalRoutesEnum.CookieSettings)) {
            this.cookieSettingsService.showCookieWall({showCloseButton: true});
            return;
        }

        const modalRef = this.modalService.open<Data>(modalComponent);

        const subscribedModal = modalRef.afterClosed$().subscribe(() => {
            this.router.navigateByUrl(this.currentUrl).finally(() => {
                this.subscriptions.unsubscribe();
            });
        });

        this.subscriptions.add(subscribedModal);
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
