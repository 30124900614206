import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WucPageBlockModule, WucRackModule, WucSkeletonModule } from '@inshared/website-ui-components';
import { DetailsRoutingModule } from './details-routing.module';
import { DetailsComponent } from './details.component';
import { DetailsContainer } from './details.container';
import {
    CorrespondenceAddressDataModule,
    CorrespondenceAddressFormModule,
    CorrespondenceAddressFutureModule,
    DetailsSectionModule,
    DetailsToggleButtonModule,
    EmailAddressModule,
    FamilyCompositionModule,
    PasswordModule,
    PaymentDetailsModule,
    PersonalDetailsModule,
    RecoverySettingsModule,
    ResidentialAddressModule,
} from '@app-de/my-zone/shared';
import { OptOutModule } from '@app-de/my-zone/shared/opt-out';
import { PanelExpandableModule } from '@app-de/shared/panel-expandable/panel-expandable.module';

@NgModule({
    imports: [
        CommonModule,
        DetailsRoutingModule,
        DetailsSectionModule,
        PersonalDetailsModule,
        ResidentialAddressModule,
        EmailAddressModule,
        PaymentDetailsModule,
        RecoverySettingsModule,
        FamilyCompositionModule,
        CorrespondenceAddressDataModule,
        CorrespondenceAddressFormModule,
        CorrespondenceAddressFutureModule,
        PanelExpandableModule,
        PasswordModule,
        DetailsToggleButtonModule,
        OptOutModule,
        WucPageBlockModule,
        WucSkeletonModule,
        WucRackModule,
    ],
    declarations: [DetailsContainer, DetailsComponent],
})
export class DetailsModule {}
