import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CredentialsInterface } from '../../authentication';
import { StorageService } from '../../core';

const TEMPORARY_CREDENTIALS_KEY = 'account-recovery-credentials';

@Injectable({
    providedIn: 'root',
})
export class AccountRecoveryStorageService {
    constructor(private storageService: StorageService) {}

    public setCredentials(credentials: CredentialsInterface): void {
        this.storageService.set(TEMPORARY_CREDENTIALS_KEY, credentials);
    }

    public getCredentials$(): Observable<CredentialsInterface> {
        return this.storageService.get$<CredentialsInterface>(TEMPORARY_CREDENTIALS_KEY);
    }

    public clear(): void {
        this.storageService.remove(TEMPORARY_CREDENTIALS_KEY);
    }
}
