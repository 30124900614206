import { Injectable } from '@angular/core';
import {
    AggregateActionService,
    AggregateDataService,
    AggregateInterface,
    AggregateState,
} from 'outshared-lib';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class ReviewAggregateService {

    constructor(
        private aggregateActionService: AggregateActionService,
        private aggregateDataService: AggregateDataService,
    ) {
    }

    public getAggregate$(ids: string[]): Observable<AggregateInterface> {
        this.aggregateActionService.getAggregate$({ ids });
        return this.aggregateDataService.getState$()
            .pipe(
                map((response: AggregateState) =>
                    response.aggregates.find((aggregate: AggregateInterface) => aggregate.key === ids.join(','))),
            );
    }
}
