import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID } from '@angular/core';
import {
    DatalayerConfigInterface,
    DatalayerService,
    AnalyticsActionService,
    CookieConsent,
    LabelEnum,
} from 'outshared-lib';
import { BlueConicService } from './core/analytics/blueconic.service';
import { SeoService } from './core/services/seo.service';
import { AuthenticationRedirectService } from '@app-de/authentication/services/authentication-redirect.service';
import { AppConfigService } from '@app-de/core/configuration';
import { CONSENT_MODE_KEY } from '@app-de/core/constants';
import { RouterService } from '@app-de/core/router/router.service';
import { LocalStorageService, StorageService } from '@inshared/shared/util-storage';
import { FeatureDetectionService, WebVitalsService } from '@inshared/shared/util';

@Component({
    selector: 'ins-root',
    templateUrl: './app.component.html',
})
export class AppComponent {
    private datalayerConfig: DatalayerConfigInterface = {
        gtmId: this.appConfigService.getConfig()?.gtmId,
        label: LabelEnum.ISDE,
    };

    constructor(
        @Inject(PLATFORM_ID) platformId: Object,
        private featureDetectionService: FeatureDetectionService,
        public seoService: SeoService,
        private dataLayerService: DatalayerService,
        private routerService: RouterService,
        private blueconicService: BlueConicService,
        private webVitalsService: WebVitalsService,
        private authenticationRedirectService: AuthenticationRedirectService,
        private analyticsActionService: AnalyticsActionService,
        private appConfigService: AppConfigService,
        @Inject(StorageService) private storage: Storage,
        @Inject(LocalStorageService) private localStorage: Storage,
    ) {
        if (isPlatformBrowser(platformId)) {
            const cookieSettings = this.storage.getItem('ls.cookie.setting') as CookieConsent;
            let consent = this.analyticsActionService.updateCookieSettings(cookieSettings);
            this.localStorage.setItem(CONSENT_MODE_KEY, JSON.stringify(consent));
            this.dataLayerService.initApiService(this.datalayerConfig);
            // ugly solution to re-fire the action but is necessary to send the update event with the correct consent
            // TODO: fix in the outshared-lib
            consent = this.analyticsActionService.updateCookieSettings(cookieSettings);
            this.localStorage.setItem(CONSENT_MODE_KEY, JSON.stringify(consent));


            this.seoService.init();
            this.routerService.init();
            this.blueconicService.init();
            this.webVitalsService.init();
            this.featureDetectionService.init();
            this.authenticationRedirectService.init();

            // TODO: WIDL-1073 Country selector toevoegen in de footer DE
            // this.countrySelectorService.init();
        }
    }
}
