<wuc-modal
    [showClose]="cookieSettingsService.isCloseButtonVisible()"
    [closeOnBackdropClick]="cookieSettingsService.isCloseButtonVisible()"
    [isVisible]="cookieSettingsService.isCookieWallVisible()"
    (modalClose)="close()"
    [modalTitle]="' '"
>
    <ng-content />
    <div slot="footer">
        <ng-content select="[slot=footer]" />
    </div>
</wuc-modal>
