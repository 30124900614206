import { Component, ElementRef, Input, Optional, ViewChild } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { WucInputBaseComponent } from '../input-base.component';
import { InputInterface } from '../input.interface';

@Component({
    selector: 'wuc-input-text',
    templateUrl: 'input-text.component.html',
    styleUrls: ['input-text.component.scss'],
    // eslint-disable-next-line
    inputs: WucInputBaseComponent.inputs,
    providers: [{ provide: WucInputBaseComponent, useExisting: WucInputTextComponent }],
})
export class WucInputTextComponent extends WucInputBaseComponent implements ControlValueAccessor, InputInterface {
    @ViewChild('input') private inputElement!: ElementRef<HTMLInputElement>;
    @Input() public maxNumberOfCharactersText?: string;
    @Input() public maxNumberOfCharacters?: number;
    @Input() public warnNumberOfCharacters?: number;
    @Input() public restrictMaxNumberOfCharacters: boolean = false;
    @Input() public maxNumberOfCharactersPositionOutside: boolean = false;

    @Input() public placeholder: string = '';
    @Input() public isPlaceholderSmall: boolean = false;

    public isDisabled: boolean = false;
    public value?: string;
    public onChange!: (value: string) => void;
    public onTouched!: () => void;

    constructor(@Optional() public override ngControl: NgControl) {
        super(ngControl);
        if (this.ngControl) {
            this.ngControl.valueAccessor = this;
        }
    }

    public override setFocus(focusDelay: number = 250): void {
        setTimeout(() => this.inputElement.nativeElement.focus(), focusDelay);
    }

    // Update value from API; API => view
    public writeValue(value: string): void {
        this.value = typeof value === 'string' ? value.trim() : value;
    }

    // Update value from view "on change"; View => API
    public registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    // Update value from view "on blur"; View => API
    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    // Set disabled state from API
    public get remainingCount(): number {
        return this.maxNumberOfCharacters ? this.maxNumberOfCharacters - (this.value?.length || 0) : 0;
    }

    public setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    public onBlur(input: HTMLInputElement) {
        input.value = typeof input.value === 'string' ? input.value.trim() : input.value;
        this.onChange(input.value);
        this.onTouched();
    }
}
