import { Component, inject } from '@angular/core';
import { WucButtonModule, WucIconUiModule, WucModalModule } from '@inshared/website-ui-components';
import { NgIf } from '@angular/common';
import { NewModalModule } from 'outshared-lib';
import { CookieSettingsService } from '../cookie-settings-service';

@Component({
    standalone: true,
    selector: 'ins-cookie-settings',
    templateUrl: 'cookie-settings.component.html',
    styleUrls: ['./cookie-settings.component.scss'],
    imports: [WucButtonModule, WucModalModule, NgIf, WucIconUiModule, NewModalModule],
})
export class CookieSettingsComponent {
    public cookieSettingsService = inject(CookieSettingsService);
}
