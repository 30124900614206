import { Component, Inject, OnInit } from '@angular/core';
import { IndicationEnum, RelationActionService, RelationDataService, StorageService } from 'outshared-lib';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { catchError, distinctUntilChanged, map, tap } from 'rxjs/operators';

@Component({
    selector: 'ins-opt-in-notification-container',
    template: `<ins-opt-in-notification
        *ngIf="showOptIn$ | async"
        (optIn)="changeOptOut($event)"
        (cancel)="hideNotification()"
    ></ins-opt-in-notification>`,
})
export class OptInNotificationContainer implements OnInit {
    public showOptIn$: Observable<boolean>;

    public optInStorage$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    public hasOptOutTimestamp$: Observable<boolean> = this.relationDataService
        .getRelation$()
        .pipe(map((relation) => !!relation?.opt_out_timestamp));

    private readonly optInStorageKey: string = 'opt-in-hide-banner';

    constructor(
        @Inject(StorageService) private storage: StorageService,
        private relationActionService: RelationActionService,
        private relationDataService: RelationDataService,
    ) {}

    public ngOnInit(): void {
        this.storage
            .get$<boolean>(this.optInStorageKey)
            .pipe(
                catchError(() => of(false)),
                tap((opt) => this.optInStorage$.next(opt)),
            )
            .subscribe();

        this.showOptIn$ = combineLatest([this.hasOptOutTimestamp$, this.optInStorage$]).pipe(
            map(([hasOptOutTimeStamp, optInStorage]) => hasOptOutTimeStamp && !optInStorage),
            distinctUntilChanged(),
        );
    }

    public changeOptOut(optOutIndication: IndicationEnum): void {
        this.relationActionService.saveOptOut$({ optOutIndication }).subscribe(() => {
            this.hideNotification();
        });
    }

    public hideNotification(): void {
        this.optInStorage$.next(true);
        this.storage.set(this.optInStorageKey, true);
    }
}
