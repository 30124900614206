import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { AccountRecoveryStorageService } from '../../account-recovery/services/account-recovery.storage.service';
import { AccountRecovery, AccountRecoveryResetAction, SetCredentialsAction } from '../../account-recovery/store';
import { CoreResetAction, ErrorInterface, ResetService, ResponseInterface } from '../../core';
import { LegacyRelationStorageService } from '../../relation/services/legacy-relation.storage.service';
import { RelationStorageService } from '../../relation/services/relation.storage.service';
import { GetRelationAction, RelationResetAction } from '../../relation/store/relation.actions';
import { Relation } from '../../relation/store/relation.state';
import { AuthenticationStatusEnum } from '../enums';
import { CredentialsInterface, SessionInterface, SessionResponseInterface } from '../interfaces';
import { hasChangedEmailError, hasGeneratedAccountError, hasTemporaryPasswordError } from '../mappers';
import { AuthenticationApiService } from '../services/authentication.api.service';
import { AuthenticationEventService } from '../services/authentication.event.service';
import { AuthenticationStorageService } from '../services/authentication.storage.service';
import { SessionService } from '../services/session.service';
import {
    AuthenticatedSessionAction,
    AuthenticationActionEnum,
    ChangedEmailLoginSuccessAction,
    ChangedEmailSessionAction,
    ExtendSessionAction,
    ExtendSessionWithTokenAction,
    GeneratedAccountLoginSuccessAction,
    GeneratedAccountSessionAction,
    LoadSessionAction,
    LoadSessionWithTokenAction,
    LoginAction,
    LoginErrorAction,
    LoginSuccessAction,
    LoginTokenAction,
    LoginTokenErrorAction,
    LoginTokenSuccessAction,
    LogoutErrorAction,
    LogoutSuccessAction,
    RequestSessionAction,
    SessionAvailableAction,
    SessionExtendedAction,
    SessionTerminatedAction,
    SessionUnavailableAction,
    TemporaryPasswordLoginSuccessAction,
    TemporaryPasswordSessionAction,
    TemporarySessionAvailableAction,
    ValidateCredentialsAction,
    ValidateCredentialsErrorAction,
    ValidateCredentialsSuccessAction,
    ValidateEmailAction,
    ValidateEmailErrorAction,
    ValidateEmailSuccessAction,
} from './authentication.actions';
import { AuthenticationSelectors } from './authentication.selectors';
import { Authentication } from './authentication.state';

@Injectable()
export class AuthenticationEffects implements OnInitEffects {
    /** Initial Triggers */
    public initialize$: Observable<Action> = createEffect(() => {
        return this.action$.pipe(
            ofType(AuthenticationActionEnum.Initialize),
            switchMap(() =>
                this.storageService.getSession$().pipe(
                    map((session: SessionInterface) => {

                        return new LoadSessionAction({ session })
                    }),
                    catchError(() =>
                        this.legacyRelationStorageService.hasLegacyUser()
                            ? of(new RequestSessionAction())
                            : of(new SessionUnavailableAction())
                    )
                )
            )
        );
    });

    /** Session Logic */
    public loadSession$: Observable<Action> = createEffect(() => {
        return this.action$.pipe(
            ofType(AuthenticationActionEnum.LoadSession),
            map((action: LoadSessionAction) => {
                const session: SessionInterface = action.payload.session;
                return SessionService.canExtend(session)
                    ? new ExtendSessionAction({ session })
                    : new LoadSessionWithTokenAction({ session });
            })
        );
    });

    public loadSessionError$: Observable<Action> = createEffect(() => {
        return this.action$.pipe(
            ofType(AuthenticationActionEnum.LoadSessionError, AuthenticationActionEnum.SessionExpired),
            tap(() => this.storageService.clear()),
            tap(() => this.relationStorageService.clear()),
            tap(() => this.legacyRelationStorageService.clear()),
            tap(() => this.accountRecoveryStorageService.clear()),
            map(() => new CoreResetAction())
        );
    });

    public loadSessionWithToken$: Observable<Action> = createEffect(() => {
        return this.action$.pipe(
            ofType(AuthenticationActionEnum.LoadSessionWithToken),
            map((action: LoadSessionWithTokenAction) => {
                const session: SessionInterface = action.payload.session;
                return SessionService.canExtendWithToken(session)
                    ? new ExtendSessionWithTokenAction({ token: session.token })
                    : new SessionTerminatedAction();
            })
        );
    });

    /** Session API */
    public requestSession$: Observable<Action> = createEffect(() => {
        return this.action$.pipe(
            ofType(AuthenticationActionEnum.RequestSession),
            switchMap(() =>
                this.apiService.extendSession$().pipe(
                    map((response: ResponseInterface) => new SessionExtendedAction({ response })),
                    catchError(() => of(new SessionUnavailableAction()))
                )
            )
        );
    });

    public extendSession$: Observable<Action> = createEffect(() => {
        return this.action$.pipe(
            ofType(AuthenticationActionEnum.ExtendSession),
            switchMap((action: ExtendSessionAction) =>
                this.apiService.extendSession$().pipe(
                    map((response: ResponseInterface) => new SessionExtendedAction({ response })),
                    catchError(() => of(new LoadSessionWithTokenAction({ session: action.payload.session })))
                )
            )
        );
    });

    public extendSessionWithToken$: Observable<Action> = createEffect(() => {
        return this.action$.pipe(
            ofType(AuthenticationActionEnum.ExtendSessionWithToken),
            switchMap((action: ExtendSessionWithTokenAction) => {
                return this.apiService.extendSessionWithToken$(action.payload.token).pipe(
                    map((response: ResponseInterface) => new SessionExtendedAction({ response })),
                    catchError(() => of(new SessionTerminatedAction()))
                );
            })
        );
    });

    /** Session Success */
    public sessionExtended$: Observable<Action> = createEffect(() => {
        return this.action$.pipe(
            ofType(AuthenticationActionEnum.SessionExtended),
            map((action: SessionExtendedAction) => {
                const response: ResponseInterface = action.payload.response;
                const session: SessionResponseInterface = response.data;
                if (hasTemporaryPasswordError(response.errors)) {
                    return new TemporaryPasswordSessionAction({ session });
                }
                if (hasGeneratedAccountError(response.errors)) {
                    return new GeneratedAccountSessionAction({ session });
                }
                if (hasChangedEmailError(response.errors)) {
                    return new ChangedEmailSessionAction({ session });
                }
                return new AuthenticatedSessionAction({ session });
            })
        );
    });

    public authenticatedSession$: Observable<Action> = createEffect(() => {
        return this.action$.pipe(
            ofType(AuthenticationActionEnum.AuthenticatedSession),
            map((action: AuthenticatedSessionAction) => {
                const session: SessionResponseInterface = { ...action.payload.session };
                session.status = AuthenticationStatusEnum.LoggedIn;
                this.relationStore$.dispatch(new GetRelationAction({ relation: session.relation }));
                return new SessionAvailableAction({ session });
            })
        );
    });

    public temporaryPasswordSession$: Observable<Action> = createEffect(() => {
        return this.action$.pipe(
            ofType(AuthenticationActionEnum.TemporaryPasswordSession),
            map((action: TemporaryPasswordSessionAction) => {
                const session: SessionResponseInterface = { ...action.payload.session };
                session.status = AuthenticationStatusEnum.TemporaryPassword;
                return new TemporarySessionAvailableAction({ session });
            })
        );
    });

    public generatedAccountSession$: Observable<Action> = createEffect(() => {
        return this.action$.pipe(
            ofType(AuthenticationActionEnum.GeneratedAccountSession),
            map((action: GeneratedAccountSessionAction) => {
                const session: SessionResponseInterface = { ...action.payload.session };
                session.status = AuthenticationStatusEnum.GeneratedAccount;
                return new TemporarySessionAvailableAction({ session });
            })
        );
    });

    /** Session End */
    public sessionAvailable$: Observable<void> = createEffect(
        () => {
            return this.action$.pipe(
                ofType(AuthenticationActionEnum.SessionAvailable),
                map((action: SessionAvailableAction) => {
                    const session = { ...action.payload.session };
                    delete session.relation;
                    this.storageService.setSession(session);
                })
            );
        },
        { dispatch: false }
    );

    public sessionUnavailable$: Observable<void> = createEffect(
        () => {
            return this.action$.pipe(
                ofType(AuthenticationActionEnum.SessionUnavailable),
                tap(() => {
                    this.storageService.clear();
                    this.relationStore$.dispatch(new RelationResetAction());
                    this.accountRecoveryStorageService.clear();
                })
            );
        },
        { dispatch: false }
    );

    public sessionTerminated$: Observable<Action> = createEffect(() => {
        return this.action$.pipe(
            ofType(AuthenticationActionEnum.SessionTerminated),
            tap(() => {
                this.storageService.clear();
                this.accountRecoveryStorageService.clear();
            }),
            map(() => new CoreResetAction())
        );
    });

    /** Login */
    public login$: Observable<Action> = createEffect(() => {
        return this.action$.pipe(
            ofType(AuthenticationActionEnum.Login),
            tap(() => this.relationStore$.dispatch(new RelationResetAction())),
            switchMap((action: LoginAction) =>
                this.apiService.login$(action.payload.request).pipe(
                    map((response: ResponseInterface) => {
                        if (hasTemporaryPasswordError(response.errors)) {
                            return new TemporaryPasswordLoginSuccessAction({ response });
                        }
                        if (hasGeneratedAccountError(response.errors)) {
                            return new GeneratedAccountLoginSuccessAction({ response });
                        }
                        if (hasChangedEmailError(response.errors)) {
                            return new ChangedEmailLoginSuccessAction({ response });
                        }
                        const session: SessionResponseInterface = response.data;
                        return new LoginSuccessAction({ session });
                    }),
                    catchError((errors: ErrorInterface[]) => of(new LoginErrorAction({ errors })))
                )
            )
        );
    });

    public loginSuccess$: Observable<Action> = createEffect(() => {
        return this.action$.pipe(
            ofType(AuthenticationActionEnum.LoginSuccess),
            tap(() => this.accountRecoveryStore$.dispatch(new AccountRecoveryResetAction())),
            tap(() => this.eventService.onLoginSuccess()),
            map((action: LoginSuccessAction) => {
                const session: SessionResponseInterface = { ...action.payload.session };
                this.relationStore$.dispatch(new GetRelationAction({ relation: session.relation }));
                session.status = AuthenticationStatusEnum.LoggedIn;
                return new SessionAvailableAction({ session });
            })
        );
    });

    public loginError$: Observable<void> = createEffect(
        () => {
            return this.action$.pipe(
                ofType(AuthenticationActionEnum.LoginError),
                tap(() => this.storageService.clear()),
                map((action: LoginErrorAction) => this.eventService.onLoginError(action.payload.errors))
            );
        },
        { dispatch: false }
    );

    /** Login Token */
    public loginToken$: Observable<Action> = createEffect(() => {
        return this.action$.pipe(
            ofType(AuthenticationActionEnum.LoginToken),
            tap(() => this.relationStore$.dispatch(new RelationResetAction())),
            switchMap((action: LoginTokenAction) =>
                this.apiService.provideLoginToken$(action.payload.request).pipe(
                    map(() => new LoginTokenSuccessAction()),
                    catchError((errors: ErrorInterface[]) => of(new LoginTokenErrorAction({ errors })))
                )
            )
        );
    });

    public loginTokenSuccess$: Observable<void> = createEffect(
        () => {
            return this.action$.pipe(
                ofType(AuthenticationActionEnum.LoginTokenSuccess),
                tap(() => this.eventService.onLoginTokenSuccess())
            );
        },
        { dispatch: false }
    );

    public loginTokenError$: Observable<void> = createEffect(
        () => {
            return this.action$.pipe(
                ofType(AuthenticationActionEnum.LoginTokenError),
                tap(() => this.storageService.clear()),
                map((action: LoginErrorAction) => this.eventService.onLoginTokenError(action.payload.errors))
            );
        },
        { dispatch: false }
    );

    public temporaryPasswordLoginSuccess$: Observable<Action> = createEffect(() => {
        return this.action$.pipe(
            ofType(AuthenticationActionEnum.TemporaryPasswordLoginSuccess),
            tap((action: TemporaryPasswordLoginSuccessAction) => {
                this.eventService.onLoginError(action.payload.response.errors);
            }),
            concatLatestFrom(() => this.store$.select(AuthenticationSelectors.getCredentials)),
            map(([action, credentials]: [TemporaryPasswordLoginSuccessAction, CredentialsInterface]) => {
                this.accountRecoveryStore$.dispatch(new SetCredentialsAction({ credentials }));
                const session: SessionResponseInterface = { ...action.payload.response.data };
                session.status = AuthenticationStatusEnum.TemporaryPassword;
                return new TemporarySessionAvailableAction({ session });
            })
        );
    });

    public generatedAccountLoginSuccess$: Observable<Action> = createEffect(() => {
        return this.action$.pipe(
            ofType(AuthenticationActionEnum.GeneratedAccountLoginSuccess),
            tap((action: GeneratedAccountLoginSuccessAction) => {
                this.eventService.onLoginError(action.payload.response.errors);
            }),
            concatLatestFrom(() => this.store$.select(AuthenticationSelectors.getCredentials)),
            map(([action, credentials]: [GeneratedAccountLoginSuccessAction, CredentialsInterface]) => {
                this.accountRecoveryStore$.dispatch(new SetCredentialsAction({ credentials }));
                const session: SessionResponseInterface = { ...action.payload.response.data };
                session.status = AuthenticationStatusEnum.GeneratedAccount;
                return new TemporarySessionAvailableAction({ session });
            })
        );
    });

    /** Logout */
    public logout$: Observable<Action> = createEffect(() => {
        return this.action$.pipe(
            ofType(AuthenticationActionEnum.Logout),
            tap(() => this.resetService.cancelPendingHttpRequests()),
            switchMap(() =>
                this.apiService.logout$().pipe(
                    map(() => new LogoutSuccessAction()),
                    catchError((errors: ErrorInterface[]) => of(new LogoutErrorAction({ errors })))
                )
            )
        );
    });

    public logoutSuccess$: Observable<Action> = createEffect(() => {
        return this.action$.pipe(
            ofType(AuthenticationActionEnum.LogoutSuccess),
            tap(() => this.storageService.clear()),
            tap(() => this.relationStorageService.clear()),
            tap(() => this.legacyRelationStorageService.clear()),
            tap(() => this.accountRecoveryStorageService.clear()),
            tap(() => this.eventService.onLogoutSuccess()),
            map(() => new CoreResetAction())
        );
    });

    public logoutError$: Observable<Action> = createEffect(() => {
        return this.action$.pipe(
            ofType(AuthenticationActionEnum.LogoutError),
            tap(() => this.storageService.clear()),
            tap(() => this.relationStorageService.clear()),
            tap(() => this.legacyRelationStorageService.clear()),
            tap(() => this.accountRecoveryStorageService.clear()),
            tap((action: LogoutErrorAction) => this.eventService.onLogoutError(action.payload.errors)),
            map(() => new CoreResetAction())
        );
    });

    /** Validate Credentials */
    public validateCredentials$: Observable<Action> = createEffect(() => {
        return this.action$.pipe(
            ofType(AuthenticationActionEnum.ValidateCredentials),
            switchMap((action: ValidateCredentialsAction) =>
                this.apiService.validateCredentials$(action.payload.request).pipe(
                    map(() => new ValidateCredentialsSuccessAction()),
                    catchError((errors: ErrorInterface[]) => of(new ValidateCredentialsErrorAction({ errors })))
                )
            )
        );
    });

    public validateCredentialsSuccess$: Observable<void> = createEffect(
        () => {
            return this.action$.pipe(
                ofType(AuthenticationActionEnum.ValidateCredentialsSuccess),
                tap(() => this.eventService.onValidateCredentialsSuccess())
            );
        },
        { dispatch: false }
    );

    public validateCredentialsError$: Observable<void> = createEffect(
        () => {
            return this.action$.pipe(
                ofType(AuthenticationActionEnum.ValidateCredentialsError),
                map((action: ValidateCredentialsErrorAction) => {
                    this.eventService.onValidateCredentialsError(action.payload.errors);
                })
            );
        },
        { dispatch: false }
    );

    public validateEmail$: Observable<Action> = createEffect(() => {
        return this.action$.pipe(
            ofType(AuthenticationActionEnum.ValidateEmail),
            switchMap((action: ValidateEmailAction) =>
                this.apiService.validateEmailAddress$(action.payload.code).pipe(
                    map(() => new ValidateEmailSuccessAction()),
                    catchError((errors: ErrorInterface[]) => of(new ValidateEmailErrorAction({ errors })))
                )
            )
        );
    });

    public validateEmailSuccess$: Observable<void> = createEffect(
        () => {
            return this.action$.pipe(
                ofType(AuthenticationActionEnum.ValidateEmailSuccess),
                tap(() => this.eventService.onValidateEmailAddressSuccess())
            );
        },
        { dispatch: false }
    );

    public validateEmailError$: Observable<void> = createEffect(
        () => {
            return this.action$.pipe(
                ofType(AuthenticationActionEnum.ValidateEmailError),
                map((action: ValidateEmailErrorAction) => {
                    this.eventService.onValidateEmailError(action.payload.errors);
                })
            );
        },
        { dispatch: false }
    );

    constructor(
        private action$: Actions,
        private store$: Store<Authentication>,
        private accountRecoveryStore$: Store<AccountRecovery>,
        private relationStore$: Store<Relation>,
        private apiService: AuthenticationApiService,
        private eventService: AuthenticationEventService,
        private storageService: AuthenticationStorageService,
        private relationStorageService: RelationStorageService,
        private legacyRelationStorageService: LegacyRelationStorageService,
        private accountRecoveryStorageService: AccountRecoveryStorageService,
        private resetService: ResetService
    ) {}

    public ngrxOnInitEffects(): Action {
        return { type: AuthenticationActionEnum.Initialize };
    }
}
